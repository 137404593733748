/* eslint-disable */

import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import './index.scss';

const ProductCard = props => {
  const {
    title,
    featuredImage,
    price,
    isSold,
    isOnSalePending,
    link = '#',
    cardSpecContent,
    logoFixedImage,
    hasLogo,
  } = props;
  let soldClass = isSold ? 'sold-flag' : '';
  let pendingClass = isOnSalePending ? 'pending-flag' : '';

  return (
    <div className="card-wrapper">
      <Link to={link} className="img-wrapper relative">
        {hasLogo && logoFixedImage ? (
          <span className="logo-wrapper">
            <Img fixed={logoFixedImage} />
          </span>
        ) : null}
        <div className="card-img flag-wrap">
          <Img fluid={{ ...featuredImage, aspectRatio: 1.3 }} alt="featured-imag" />
          <div className={`${soldClass} ${pendingClass}`} />
        </div>
        <p className="card-price">${price}</p>
        <ul className="card-spec no-bullet">{cardSpecContent}</ul>
        <div className="overlay-description">
          <p>{title}</p>
        </div>
      </Link>
      <Link to={link} className="img-wrapper relative">
        <h5 className="title text-black smaller">{title}</h5>
      </Link>
    </div>
  );
};

export default ProductCard;
