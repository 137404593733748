import React from 'react';
import PropTypes from 'prop-types';

// import './loader.scss';

const Loader = ({ className }) => <div className={className} />;

export default Loader;

Loader.defaultProps = {
  className: 'lds-dual-ring',
};

Loader.propTypes = {
  className: PropTypes.string,
};
