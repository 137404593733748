import React from 'react';
import PropTypes from 'prop-types';
import ReactBlockUI from 'react-block-ui';

import Loader from './loader';
// import 'react-block-ui/style.css';

const BlockUI = ({ children, tag, loader, isBlocking }) => {
  return (
    <ReactBlockUI tag={tag} blocking={isBlocking} loader={loader}>
      {children}
    </ReactBlockUI>
  );
};

export default BlockUI;

BlockUI.defaultProps = {
  tag: 'div',
  loader: Loader,
  isBlocking: false,
};

BlockUI.propTypes = {
  tag: PropTypes.string,
  loader: PropTypes.func,
  isBlocking: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
