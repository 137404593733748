/* eslint-disable */

import React from 'react';
//
import './index.scss';
import ProductCard from '../ProductCard';

const BikeCard = props => {
  const { slug, year, date, mileage, engine, ...restProps } = props;

  return (
    <ProductCard
      link={`/motorcycles/${slug}`}
      cardSpecContent={
        <>
          <li className="milage with-icon">{mileage}</li>
          <li className="cubage with-icon">{engine}cc</li>
          <li className="date with-icon">{year || date}</li>
        </>
      }
      {...restProps}
    />
  );
};

export default BikeCard;
