import PropTypes from 'prop-types';
import React, { useState } from 'react';
//
import IconSearch from '../../assets/svg/icon-search.svg';
import IconArrowDown from '../../assets/svg/icno-arrow-down.svg';

const minYear = 1995;
const maxYear = new Date().getFullYear();

/**
 * Return array between min and max
 * @param {int} min
 * @param {int} max
 */
const generateYearsRange = (min, max) => {
  const array = [];
  // eslint-disable-next-line
  for (let i = min; i <= max; i++) {
    array.push({ value: i, title: i });
  }

  return array;
};

/**
 * Prepare items for DDL
 * @param {array} list List that needs to be restructured to fit DDL
 */
const mapListForDDL = list => {
  return list.map(({ id, name }) => ({ value: id, title: name }));
};

const getSelectedItem = (list, value) => {
  if (!list || !list.length || !value) return null;

  return list.find(item => item.value === value);
};

// eslint-disable-next-line
const DDL = ({ name, list, value, onChange, ...rest }) => {
  const [dropDownMenu, setVisible] = useState(false);
  const selectedItem = getSelectedItem(list, value);

  const menuClickHandle = e => {
    e.preventDefault();
    //
    setVisible(!dropDownMenu);
  };

  const handleSelect = id => {
    setVisible(false);
    //
    return onChange && onChange({ target: { name, value: id } });
  };

  return (
    <div className={`ddl ${dropDownMenu ? 'open' : ''}`}>
      <button type="button" onClick={menuClickHandle} className="select">
        {selectedItem ? selectedItem.title : 'Choose'}
        <IconArrowDown />
      </button>
      <ul className="dropdown-menu" name={name}>
        <li onClick={() => handleSelect(null)}>
          <button type="button">Reset</button>
        </li>
        {
          // eslint-disable-next-line
          list.map(({ value, title }) => {
            return (
              <li key={value}>
                <button type="button" onClick={() => handleSelect(value)} key={value} value={value}>
                  {title}
                </button>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

const Form = ({ defaultState, makes, models, onChange, onReset }) => {
  const {
    selectedMakeId,
    selectedModelId,
    selectedMaxYear,
    searchTitleTerm,
    selectedMinPrice: initMinPrice,
    selectedMaxPrice: initMaxPrice,
  } = defaultState;
  const [priceState, setPriceState] = useState({ selectedMinPrice: initMinPrice, selectedMaxPrice: initMaxPrice });

  const handleChange = e => {
    console.log('handle change');
    const value = e.target.value === '-1' ? null : e.target.value;

    return onChange && onChange({ ...defaultState, ...priceState, [e.target.name]: value });
  };

  const handlePriceChange = e => {
    const value = e.target.value === '-1' ? null : e.target.value;

    const updatedState = { ...priceState, [e.target.name]: Number(value) };

    setPriceState(updatedState);
  };

  return (
    <form>
      <div className="search-box">
        <input
          type="text"
          name="searchTitleTerm"
          placeholder="Search by title"
          value={searchTitleTerm}
          //
          onChange={handleChange}
        />
        <IconSearch />
      </div>
      <label className="search-label" for="selectedMakeId">
        Search
      </label>
      <div className="search-criteria">
        <div className="text-right">
          <button className="clear-button" type="button" onClick={() => onReset && onReset()}>
            Clear
          </button>
        </div>
        <label>Make:</label>
        <DDL
          name="selectedMakeId"
          value={selectedMakeId}
          list={mapListForDDL(makes)}
          //
          onChange={handleChange}
        />
        <label>Model:</label>
        <DDL
          name="selectedModelId"
          value={selectedModelId}
          list={mapListForDDL(models)}
          //
          onChange={handleChange}
        />
        <label>Year:</label>
        <DDL
          name="selectedMaxYear"
          value={selectedMaxYear}
          list={generateYearsRange(minYear, maxYear)}
          //
          onChange={handleChange}
        />
        <label>Min Price:</label>
        <input
          type="text"
          name="selectedMinPrice"
          placeholder="Min price"
          value={priceState.selectedMinPrice}
          className="min-price"
          //
          onChange={handlePriceChange}
        />
        <label>Max Price:</label>
        <input
          type="text"
          name="selectedMaxPrice"
          placeholder="Max price"
          className="max-price"
          value={priceState.selectedMaxPrice}
          //
          onChange={handlePriceChange}
        />
        {/* eslint-disable-next-line */}
        <button type="button" className="apply" onClick={handleChange}>
          Apply price filter
        </button>
      </div>
    </form>
  );
};

Form.propTypes = {
  defaultState: PropTypes.shape({
    selectedMakeId: PropTypes.string,
    selectedModelId: PropTypes.string,
    searchTitleTerm: PropTypes.string,
    selectedMaxYear: PropTypes.number,
    //
    selectedMinPrice: PropTypes.number,
    selectedMaxPrice: PropTypes.number,
  }).isRequired,
};

export default Form;
